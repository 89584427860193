import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  background-color: 00000;
  img {
    width: 250px;
  }
`;
