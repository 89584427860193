import styled from 'styled-components';

export const TemplateStyled = styled.div`
  font-family: 'Montserrat', sans-serif;
  background-color: white;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;

  @media (max-width: 992) {
    height: auto;
    overflow: auto;
  }
`;
