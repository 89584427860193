import React, { useContext } from 'react';
import Icon from '../Icon';
import success from '../../assets/icons/success.svg';
import processing from '../../assets/icons/processing.svg';
import attention from '../../assets/icons/attention.svg';
import * as S from './styles';
import Title from '../Title';
import { color } from '../../styles';
import Button from '../Button';
import CopyButton from '../CopyButton';
import AuthContext from '../../context/auth';
import { credentials } from '../../services/auth';

interface IModal {
  status?: 'success' | 'processing' | 'credentials';
}

const determineStatus = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authCtx = useContext(AuthContext);

  if (
    authCtx.customer?.hasCredentials === true &&
    authCtx.customer?.hasRetrivedCredential === false
  ) {
    return 'credentials';
  } else if (
    authCtx.customer?.hasCredentials === false &&
    authCtx.customer?.hasRetrivedCredential === false
  ) {
    return 'processing';
  } else {
    return 'success';
  }
};

function ModalResponse({ status, ...props }: IModal) {
  const authCtx = useContext(AuthContext);

  const currentStatus = status || determineStatus();

  const _status = {
    success: {
      icon: attention,
      title: 'Precisa de Ajuda?',
      text: [
        <p>Parece que suas credenciais de acesso já foram geradas.</p>,
        <p>
          Se por algum motivo estiver enfrentando dificuldades ou tiver perguntas, não hesite em
          entrar em contato conosco através do <u>checkout-support-br@transfero.com</u>. Além disso,
          você pode encontrar informações úteis em nossa documentação online.
        </p>,
      ],
    },
    processing: {
      icon: processing,
      title: 'Credenciais sendo criadas',
      text: [
        <p>Estamos criando suas credenciais para integração</p>,
        <p>
          Assim que estiverem prontas, te avisaremos por e-mail. Caso não encontre, verifique a
          caixa de spam.
        </p>,
        <p>
          Se não receber o email, entre em contato conosco através do endereço <u>checkout-support-br@transfero.com</u>
        </p>,
      ],
    },
    credentials: {
      icon: success,
      title: 'Conta criada com sucesso!',
      text: [
        <p>
          Parabéns! Sua conta foi criada com sucesso. Por favor, <b>prossiga</b> para gerar suas
          credenciais de acesso.
        </p>,
        <p>
          Elas são geradas apenas dessa vez, por isso é essencial que você as guarde em um local
          seguro.
        </p>,
        <p>
          Se encontrar qualquer dificuldade ou se tiver perguntas, não hesite em contatar nosso
          suporte em <u>checkout-support-br@transfero.com</u> ou visitar nossa documentação online.
        </p>,
      ],
    },
  };

  const { icon, title, text } = _status[currentStatus];

  return (
    <S.ModalContainer>
      <S.ModalContent>
        <S.Modal>
          <Icon size='xxl' src={icon} />
        </S.Modal>
        <S.TitleModal>{title}</S.TitleModal>
        <S.TextModal>
          {text.map((paragraph, index) => (
            <Title key={index} size='lg' color={color.gray[500]} fontWeight={400}>
              {paragraph}
            </Title>
          ))}
        </S.TextModal>
        {authCtx.credentials && currentStatus === 'credentials' && (
          <>
            <Title color={color.gray[600]}>Application Key</Title>
            <S.Crendentials>
              <S.TextCrendentials>{authCtx.credentials?.clientId}</S.TextCrendentials>
              <CopyButton text={authCtx.credentials?.clientId || ''} />
            </S.Crendentials>
            <Title color={color.gray[600]}>Application Token</Title>
            <S.Crendentials>
              <S.TextCrendentials>{authCtx.credentials?.secreat}</S.TextCrendentials>
              <CopyButton text={authCtx.credentials?.secreat || ''} />
            </S.Crendentials>
          </>
        )}
        {currentStatus === 'credentials' && !authCtx.credentials?.clientId && (
          <S.ButtonModal>
            <Button size='lg' onClick={() => authCtx.getCrendentials()} loading={authCtx.loading}>
              Gerar credenciais
            </Button>
          </S.ButtonModal>
        )}
      </S.ModalContent>
    </S.ModalContainer>
  );
}

export default ModalResponse;
