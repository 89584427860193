import api from '../config/services/api';
import {
  CrendentialsResponse,
  CustomerResponse,
  SignInProps,
  SignInResponse,
} from '../config/types/user';

export const checkCustomer = ({ email }: { email: string }): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    api
      .get(`/Customers/Check/${email}?product=TransferoCheckout`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const customer = () => {
  return new Promise<CustomerResponse>((resolve, reject) => {
    api
      .get(`/Customers`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signIn = ({ email, password }: SignInProps) => {
  return new Promise<SignInResponse>(async (resolve, reject) => {
    try {
      const res = await api.post('/Customers/Login', {
        email: email,
        password: password,
      });
      resolve({
        tokenType: res.data.tokenType,
        createdAt: res.data.createdAt,
        extExpiresIn: res.data.extExpiresIn,
        idToken: res.data.idToken,
        refreshToken: res.data.refreshToken,
        accessToken: res.data.accessToken,
        scope: res.data.scope,
        expiresIn: res.data.expiresIn,
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const RefreshToken = ({ refreshToken }: { refreshToken: string }) => {
  return new Promise<SignInResponse>(async (resolve, reject) => {
    try {
      const res = await api.post('/Customers/RefreshToken', {
        refreshToken: refreshToken,
      });
      resolve({
        accessToken: res.data.accessToken,
        createdAt: res.data.createdAt,
        expiresIn: res.data.expiresIn,
        extExpiresIn: res.data,
        idToken: res.data.idToken,
        refreshToken: res.data.refreshToken,
        scope: res.data.scope,
        tokenType: res.data.tokenType,
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const newPassword = ({
  email,
  code,
  newPassword,
}: {
  email: string;
  code: string;
  newPassword: string;
}) => {
  return new Promise<SignInResponse>(async (resolve, reject) => {
    try {
      const res = await api.post('/Customers', {
        email: email,
        password: newPassword,
        emailCode: code,
        product: 'TransferoCheckout',
      });
      resolve({
        accessToken: res.data.accessToken,
        createdAt: res.data.createdAt,
        expiresIn: res.data.expiresIn,
        extExpiresIn: res.data,
        idToken: res.data.idToken,
        refreshToken: res.data.refreshToken,
        scope: res.data.scope,
        tokenType: res.data.tokenType,
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const credentials = () => {
  return new Promise<CrendentialsResponse>((resolve, reject) => {
    api
      .get(`/Customers/credential`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
