import React, { useContext, useState } from 'react';
import { Button, Card, TextInput, Title } from '../../components';
import arrowRigth from '../../assets/icons/arrowRigth.svg';
import * as S from './styles';
import { Form, Formik } from 'formik';
import AuthContext from '../../context/auth';
import * as Yup from 'yup';

function EmailVerification() {
  const authCtx = useContext(AuthContext);
  const [signInError, setSignInError] = useState('');
  const [checkError, setCheckError] = useState('');

  const initialValues = {
    email: '',
    password: '',
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Insira um email válido.').required('Insira seu email'),
    password: authCtx.existingUser ? Yup.string().required('Insira sua senha') : Yup.string(),
  });

  const onSubmit = () => {
    if (authCtx.existingUser) {
      authCtx.signIn().catch((e: any) => {
        setSignInError('Usuário ou senha inválidos.');
      });
    } else {
      authCtx.checkCustomers().catch(() => {
        setCheckError('O email fornecido não é válido ou já está em uso.');
      });
    }
  };

  return (
    <Card textHeader='Etapa 1 de 4' titleCard='Insira seu e-mail para se cadastrar ou acessar sua conta'>
      <Formik initialValues={initialValues} validationSchema={SignupSchema} onSubmit={onSubmit}>
        {({ errors, handleChange }) => (
          <Form>
            <>
              <Title>Email</Title>
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  authCtx.setData({ email: e.target.value });
                }}
                name='email'
                error={errors.email || checkError}
              />
              {authCtx.existingUser && (
                <>
                  <Title>Senha</Title>
                  <TextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      authCtx.setData({ password: e.target.value });
                    }}
                    name='password'
                    type='password'
                    error={errors.password ?? signInError}
                    password
                  />
                </>
              )}
            </>
            <S.ButtonEmailVerification>
              <Button
                type='submit'
                icon
                src={arrowRigth}
                size='lg'
                variant='primary'
                loading={authCtx.loading}
              >
                Continuar
              </Button>
              {!authCtx.existingUser && (
                <S.Text>
                  <Title size='sm' >
                    Ao prosseguir com o cadastro, você está de acordo com os
                    <S.Link href='/Termos.pdf' download>
                      {' '}
                      termos de uso
                    </S.Link>
                  </Title>
                </S.Text>
              )}
            </S.ButtonEmailVerification>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default EmailVerification;
