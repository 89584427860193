import React from 'react';
import { color } from '../../styles';

interface ISuccessCircle {
  SuccessCircle?: boolean;
}

const SuccessCircle = ({ SuccessCircle }: ISuccessCircle) => {
  return (
    <svg width='17px' height='17px' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.31935 8.48999L7.44711 10.6177L11.7026 6.36223M15.6035 8.48999C15.6035 12.4071 12.4281 15.5825 8.51099 15.5825C4.59389 15.5825 1.41846 12.4071 1.41846 8.48999C1.41846 4.57289 4.59389 1.39746 8.51099 1.39746C12.4281 1.39746 15.6035 4.57289 15.6035 8.48999Z'
        stroke={ SuccessCircle ? color.success[500] : color.gray[300]}
        strokeWidth='1.48943'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SuccessCircle;
