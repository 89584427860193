import * as Yup from 'yup';

export const SignupSchema = Yup.object().shape({
  taxId:Yup.string()
  .required('Campo obrigatório.').test(
    'test-invalid-cpf',
    'Insira um CNPJ válido',
    (taxId) =>  validarCNPJ(taxId)),
  companyName: Yup.string().required('Campo obrigatório.'),
  businessEntity: Yup.string().required('Campo obrigatório.'),
});

export const businessEntity = [
  {
    value: 'Empresário Individual (EI)',
    label: 'Empresário Individual (EI)',
  },
  {
    value: 'Sociedade Empresária Limitada.',
    label: 'Sociedade Empresária Limitada.',
  },
  {
    value: 'Sociedade Empresária Unipessoal Limitada.',
    label: 'Sociedade Empresária Unipessoal Limitada.',
  },
  {
    value: 'Sociedade Simples – Pura ou limitada.',
    label: 'Sociedade Simples – Pura ou limitada.',
  },
  {
    value: 'Sociedade Simples Unipessoal Limitada.',
    label: 'Sociedade Simples Unipessoal Limitada.',
  },
  {
    value: 'Sociedade Anônima Aberta e Fechada.',
    label: 'Sociedade Anônima Aberta e Fechada.',
  },
];

function validarCNPJ(cnpj: string): boolean {
  const cnpjLimpo = cnpj.replace(/\D/g, '');

  if (cnpjLimpo.length !== 14) {
    return false;
  }

  let soma = 0;
  let peso = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpjLimpo.charAt(i)) * peso[i];
  }
  let resto = soma % 11;
  let digito1 = resto < 2 ? 0 : 11 - resto;

  soma = 0;
  peso = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpjLimpo.charAt(i)) * peso[i];
  }
  resto = soma % 11;
  let digito2 = resto < 2 ? 0 : 11 - resto;

  return parseInt(cnpjLimpo.charAt(12)) === digito1 && parseInt(cnpjLimpo.charAt(13)) === digito2;
}
