import React from 'react';
import * as S from './styles';
import pt from 'react-phone-input-2/lang/pt.json';
import { FormikErrors } from 'formik';
import Title from '../Title';
import { color } from '../../styles';

interface IInputPhone {
  onChange: (v: string) => void;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}

function InputPhone({ onChange, error }: IInputPhone) {
  return (
    <>
      <S.StyledInputPhone
        country='br'
        onChange={onChange}
        localization={pt}
        error={String(!!error)}
      />
      {String(!!error) && <Title color={color.error[500]}>{error?.toString()}</Title>}
    </>
  );
}

export default InputPhone;
