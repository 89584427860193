import { Route, Routes } from 'react-router-dom';
import { AccessPassword, EmailVerification, VerificationCode } from '../views';
import { Template } from '../components';

const AuthRoutes = () => {
  return (
    <Template>
      <Routes>
        <Route path='/' element={<EmailVerification />} />
        <Route path='/verificationcode' element={<VerificationCode />} />
        <Route path='/accesspassword' element={<AccessPassword />} />
      </Routes>
    </Template>
  );
};

export default AuthRoutes;
