import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonLink, Card, PinField, Title } from '../../components';
import arrowRigth from '../../assets/icons/arrowRigth.svg';
import * as S from './styles';
import OnboardingContext from '../../context/onboarding';
import AuthContext from '../../context/auth';

function VerificationCode() {
  const onboardingCtx = useContext(OnboardingContext);
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState(false);

  useEffect(() => {
    onboardingCtx.validationCode();
  }, []);

  const onSubmit = () => {
    onboardingCtx.validationEmailCode().catch((e: any) => {
      setError(true);
    });
  };

  return (
    <Card
      backButton
      textHeader='Etapa 1 de 4'
      titleCard='Verifique sua conta'
      subtitle={`Digite o código que mandamos para o e-mail
        ${authCtx.data?.email}
      . Essa é uma medida de segurança para tornar sua conta ainda mais segura.`}
    >
      <PinField
        onChange={(v) => authCtx.setData({ code: v })}
        error={error}
        errorMessage={'Código inválido. Revise a digitação e tente novamente.'}
        length={6}
      />
      <S.ButtonVerificationCode>
        <Button onClick={() => onSubmit()} size='lg' icon src={arrowRigth} loading={onboardingCtx.loading}>
          Verificar
        </Button>
      </S.ButtonVerificationCode>
      <S.ResendCode>
        <Title>
          Se você precisar, clique em 
          <ButtonLink onClick={() => onboardingCtx.validationCode()} >
          reenviar código
          </ButtonLink>
        </Title>
      </S.ResendCode>
    </Card>
  );
}

export default VerificationCode;
