import React from 'react';
import * as S from './styles';
import { IPinField } from './types';
import Title from '../Title';
import { color } from '../../styles';

function PinField({ onChange, length, error, name, errorMessage }: IPinField) {
  return (
    <>
      <S.ContentPinField>
        <S.PinFieldStyles
          length={length}
          onChange={onChange}
          name={name}
          error={error}
          validate={/^[0-9]$/}
        />
      </S.ContentPinField>
      <>{error && <Title color={color.error[500]}>{error}</Title>}</>
    </>
  );
}

export default PinField;
