import styled from "styled-components";

export const ButtonVerificationCode = styled.div`
margin-top: 40px ;
`
export const ResendCode = styled.div`
display: flex;
flex-direction: row;
margin: 10px 0 10px;
`
