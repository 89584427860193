import React from 'react';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import copy from '../../assets/icons/copy.svg';
interface ICopyButton {
  text: string;
  title?: string;
}

function CopyButton({ text, title }: ICopyButton) {
  const copyToClipboard = ({ cp }: { cp: string }) => {
    navigator.clipboard.writeText(cp);
  };

  return (
    <Tooltip text='Copied' id='copy' events={['click']} place='top-start' offSet={18}>
      <Icon
        src={copy}
        pointer
        onClick={() =>
          copyToClipboard({
            cp: text,
          })
        }
      />
    </Tooltip>
  );
}

export default CopyButton;
