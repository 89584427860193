import React from 'react';
import * as S from './styled';
import { ButtonProps } from './types.js';
import Icon from '../Icon';
import { color } from '../../styles';
import { PulseLoader } from 'react-spinners';

const Button = (props: ButtonProps ) => {

  const { variant, size, children, icon, iconSide = "right", src, type, disabled, loading} = props;

  return (
    <S.Button
      type={type}
      variant={variant}
      size={size}
      icon={icon}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <PulseLoader size={8} color={color.primary[600]} loading={loading} />
      ) : (
        <>
          {iconSide === 'left' && icon && <Icon size={'md'} src={src} />}

          {children || 'Button'}

          {iconSide === 'right' && icon && <Icon size={'md'} src={src} />}
        </>
      )}
    </S.Button>
  );
};

export default Button;
