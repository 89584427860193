import React, { useContext } from 'react';
import { Button, Card, InputPhone, TextInput, Title } from '../../components';
import arrowRigth from '../../assets/icons/arrowRigth.svg';
import * as S from './styled';
import helpCircle from '../../assets/icons/helpCircle.svg';
import { Form, Formik } from 'formik';
import OnboardingContext from '../../context/onboarding';
import AuthContext from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import { SignupSchema } from './utils';

function CompanyRegistrationContact() {
  const onboardingCtx = useContext(OnboardingContext);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const inititialValues = {
    site: '',
    email: authCtx.data.email || '',
    phone: '',
  };

  return (
    <Card textHeader='Etapa 2 de 4' titleCard='Complete os dados para criar sua conta'>
      <Formik
        initialValues={inititialValues}
        validationSchema={SignupSchema}
        onSubmit={() => navigate('/companyregistration')}
      >
        {({ errors, handleChange, setFieldValue, values }) => (
          <Form>
            <>
              <Title>Email</Title>
              <TextInput
                name='email'
                disabled={authCtx.data.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onboardingCtx.setFilter({ email: e.target.value });
                }}
                value={values.email}
                error={errors.email}
              />
            </>
            <>
              <Title>Telefone</Title>
              <InputPhone
                onChange={(v) => {
                  setFieldValue('phone', v);
                  onboardingCtx.setFilter({ phone: v});
                }}
                error={errors.phone}
              />
            </>
            <>
              <Title>Site</Title>
              <TextInput
                tooltip
                tooltipText='Insira o link da página principal do site da sua empresa'
                id='site'
                name='site'
                icon={helpCircle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onboardingCtx.setFilter({ site: e.target.value });
                }}
                error={errors.site}
              />
            </>
            <S.ButtonAccount>
              <Button icon src={arrowRigth} size='lg' variant='primary' type='submit'>
                Continuar
              </Button>
            </S.ButtonAccount>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default CompanyRegistrationContact;
