import styled from 'styled-components';
import { ButtonStyleProps } from './types';
import { sizes, variants, aligns } from './variants';

export const Button = styled.button<ButtonStyleProps>`
  font-size: 16px;
  font-weight: 600;
  border: 0;
  max-height: 50px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  line-height: 1;
  ${({ variant }) => variants[variant || 'primary']}
  ${({ size }) => sizes[size || 'md']}
  ${({ align }) => aligns[align || 'center']}
`;
