import styled from 'styled-components';
import { color } from '../../styles';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CardContent = styled.div`
  width: 50%;
  max-width: 450px;
  padding: 10px 40px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  border: 1px solid ${color.gray[100]};
  position: absolute;
  top: 17%;
  margin: 10px;
  @media (max-width: 720px) {
    width: auto;
    border: none;
    box-shadow: none;
    padding: 10px;
    height: 100vw;
  }
`;

export const HeaderCard = styled.div`
  width: calc(100% - 46px);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding-bottom: 8px;
  justify-content: space-between;
  color: ${color.gray[600]};
  margin-top: 20px;
`;

export const HeaderText = styled.div`
  flex-grow: 1;
`;

export const TitleCard = styled.p`
  font-size: 27px;
  font-weight: 600;
  text-align: left;
  padding-right: 12px;
  margin-bottom: 2px;
`;

export const SubtitleCard = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  font-weight: 500;
  text-align: left;
  padding-right: 12px;
  color: ${color.gray[600]};
`;
