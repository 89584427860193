import styled from 'styled-components';
import { color } from '../../styles';

export const ButtonEmailVerification = styled.div`
  margin: 28px 0 20px 0;
`;
export const Link = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: ${color.primary[600]};
  &:hover {
    color: ${color.primary[700]};
  }
`;

export const Text = styled.div`
  margin: 15px 0 30px;
  font-weight: 100;
`;
