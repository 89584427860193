import React, { ReactNode, useRef } from 'react';
import * as S from './styles';
import { EventsType, TooltipRefProps, Tooltip as Tooltips } from 'react-tooltip';

interface ITooltip {
  children?: ReactNode;
  text?: string;
  id?: string;
  place?: 'top' | 'bottom' | 'top-start';
  events?: EventsType[];
  offSet?: number;
}

function Tooltip({ children, text, id, place = 'top', events = ['hover'] , offSet}: ITooltip) {
  const tooltipRef = useRef<TooltipRefProps>(null);

  setTimeout(() => {
    tooltipRef.current?.close();
  }, 1000);

  return (
    <S.TooltipsContent>
      <Tooltips
        id={id}
        place={place}
        events={events}
        ref={tooltipRef}
        offset={offSet}
        afterShow={() =>
          setTimeout(() => {
            tooltipRef.current?.close();
          }, 1000)
        }
      >
        {text}
      </Tooltips>
      <a data-tooltip-id={id}>{children}</a>
    </S.TooltipsContent>
  );
}

export default Tooltip;
