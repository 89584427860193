import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import api from '../config/services/api';
import * as services from '../services/auth';
import authStore from './authStore';
import { CrendentialsResponse, CustomerResponse, SignInResponse } from '../config/types/user';
import { useNavigate } from 'react-router-dom';
interface ContextProps {
  existingUser?: boolean;
  user?: SignInResponse | null;
  email?: string;
  password?: string;
  newPassword?: string;
  signIn?: any;
  checkCustomers?: any;
  code?: string;
  setCode?: (code: string) => void;
  signOut?: () => void;
  data?: any;
  setData?: any;
  loading?: boolean;
  setLoading?: any;
  setInitialLoading?: any;
  initialLoading?: boolean;
  children?: ReactNode;
  getCustomer?: any;
  customer?: CustomerResponse | null;
  newPasswordCreated?: any;
  getCrendentials?: any;
  credentials?: CrendentialsResponse | null;
}

const storage = sessionStorage;

const AuthContext = createContext<ContextProps>({
  data: {},
  user: null,
  loading: true,
  initialLoading: false,
  existingUser: false,
  email: '',
  code: '',
  password: '',
  newPassword: '',
  signIn: () => {},
  customer: null,
});

export const AuthProvider: FC<ContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(authStore, {
    data: {},
  });

  const setData = (data: any) => {
    dispatch({
      payload: { ...state.data, data },
      key: 'data',
      action: 'data',
    });
  };

  const setLoading = (loading: boolean) => {
    dispatch({ payload: loading, key: 'loading', action: 'loading' });
  };

  const setInitialLoading = (initialLoading: boolean) => {
    dispatch({ payload: initialLoading, key: 'initialLoading', action: 'initialLoading' });
  };

  useEffect(() => {
    async function loadStorageData() {
      const storagedToken = storage.getItem('@Auth:token');
      const storagedUser = storage.getItem('@Auth:user');
      const storagedEmail = storage.getItem('@Auth:email');

      if (storagedEmail) {
        setData({ email: storagedEmail });
      }
      if (storagedToken) {
        const token: string = storagedToken;
        api.defaults.headers.common = {
          Authorization: token,
        };
      }
      if (storagedUser) {
        let userJson: SignInResponse = JSON.parse(storagedUser);
        dispatch({
          payload: userJson,
          key: 'user',
          action: 'user',
        });
      }
    }
    loadStorageData();
  }, []);

  const checkCustomers = async () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      services
        .checkCustomer({ email: state.data.email })
        .then((response: any) => {
          setLoading(false);
          dispatch({
            payload: response.existingUser,
            key: 'existingUser',
            action: 'existingUser',
          });
          resolve(response);
          if (response.existingUser === false) {
            navigate('/verificationcode');
          }
        })
        .catch((e) => {
          setLoading(false);
          reject(e);
        });
    });
  };

  const newPasswordCreated = () => {
    return new Promise((_resolve, reject) => {
      setLoading(true);
      services
        .newPassword({
          email: state.data.email,
          code: state.data.code,
          newPassword: state.data.newPassword,
        })
        .then((response) => {
          setLoading(false);
          api.defaults.headers.common = {
            Authorization: `${response.tokenType} ${response.accessToken}`,
          };
          storage.setItem('@Auth:token', `${response.tokenType} ${response.accessToken}`);
          storage.setItem('@Auth:user', JSON.stringify(response));
          storage.setItem('@Auth:email', state.data.email);

          dispatch({
            payload: JSON.stringify(response),
            key: 'user',
            action: 'user',
          });
        })
        .catch((error: string) => {
          setLoading(false);
          reject(error);
        });
    });
  };

  const signIn = async () => {
    return new Promise((_resolve, reject) => {
      setLoading(true);

      services
        .signIn({ email: state.data.email, password: state.data.password })
        .then((response) => {
          setLoading(false);
          api.defaults.headers.common = {
            Authorization: `${response.tokenType} ${response.accessToken}`,
          };
          storage.setItem('@Auth:token', `${response.tokenType} ${response.accessToken}`);
          storage.setItem('@Auth:user', JSON.stringify(response));
          storage.setItem('@Auth:email', state.data.email);

          dispatch({
            payload: JSON.stringify(response),
            key: 'user',
            action: 'user',
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.code) {
            reject({ code: err?.response?.data?.code, message: err?.response?.data?.message });
          } else {
            reject({ code: 0, message: 'error' });
          }
        });
    });
  };

  const getCustomer = () => {
    setInitialLoading(true);
    return new Promise((resolve, reject) => {
      services
        .customer()
        .then((res) => {
          setInitialLoading(false);
          dispatch({
            payload: res,
            key: 'customer',
            action: 'customer',
          });
          resolve(res);
        })
        .catch((e) => {
          setInitialLoading(false);
          reject(e);
        });
    });
  };

  const getCrendentials = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      services
        .credentials()
        .then((res) => {
          setLoading(false);
          dispatch({
            payload: res,
            key: 'credentials',
            action: 'credentials',
          });
          resolve(res);
        })
        .catch((e) => {
          setLoading(false);
          reject(e);
        });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setData,
        setLoading,
        setInitialLoading,
        checkCustomers,
        signIn,
        getCustomer,
        newPasswordCreated,
        getCrendentials,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
