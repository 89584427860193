import styled from 'styled-components';
import { color } from '../../styles';
import { FormikErrors } from 'formik';
import InputMask from 'react-input-mask';

interface Props {
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}
export const InputStyled = styled.input`
  width: 100%;
  font-size: 15px;
  border: none;
  color: ${color.gray[700]};
  border-radius: 5px;
  padding-left: 8px;

  &:focus {
    outline: none;
    border-color: ${color.primary[300]};
  }
`;

export const InputTextContainer = styled.label<Props>`
  height: 42px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.error === 'true' ? color.error[500] : color.gray[300])};

  a {
    display: flex;
    align-items: center;
  }

  &:hover {
    border: 1px solid ${(props) => (props.error === 'true' ? color.error[500] : color.primary[300])};
  }
`;


export const InputCnpj = styled(InputMask)<Props>`
  width: 100%;
  font-size: 15px;
  border: none;
  color: ${color.gray[700]};
  border-radius: 5px;
  padding-left: 8px;

  &:focus {
    outline: none;
    border-color: ${color.primary[300]};
  }
`;