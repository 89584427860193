import styled from 'styled-components';
import { color } from '../../styles';

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  width: 50%;
  max-width: 450px;
  padding: 10px 40px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  border: 1px solid ${color.gray[100]};
  position: absolute;
  top: 17%;
  margin: 10px;
  @media (max-width: 720px) {
    width: auto;
    border: none;
    box-shadow: none;
    padding: 10px;
    height: 100vw;
  }
`;

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const TitleModal = styled.p`
  font-size: 32px;
  font-weight: 600;
  margin: 20px 0 20px 0;
  text-align: center;
`;

export const TextModal = styled.div`
  margin-right: 20px;

`;

export const ButtonModal = styled.div`
  margin-bottom: 30px;
`;

  export const TextCrendentials = styled.div`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  padding-left: 20px;
  
  &::-webkit-scrollbar {
    display: none;
  }
  `;

  export const Crendentials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${color.primary[700]};
  padding: 5px;
  border-radius: 8px;
  background-color: ${color.gray[50]};
  border: 1px solid ${color.gray[50]};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 10px;
`;
