import React from 'react';
import { Title } from '../../../components';
import * as S from './styles';
import { color } from '../../../styles';
import SuccessCircle from '../../../assets/icons/successCircle';

export interface IPasswordCheck {
  children: any;
  isValid: boolean;
}

function PasswordCheck({ children, isValid }: IPasswordCheck) {
  return (
    <S.PasswordCheckTitle>
      <SuccessCircle SuccessCircle={isValid} />
      <Title fontWeight={400} color={color.gray[500]}>
        {children}
      </Title>
    </S.PasswordCheckTitle>
  );
}

export default PasswordCheck;
