import { StylesConfig } from 'react-select';
import { Option } from '.';
import { color } from '../../styles';

export const CheckboxStyles: StylesConfig<Option> = {
  option: (provided, state) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: state.data.label === 'Success' ? 'green' : 'black',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#e0e0e0' : 'transparent',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.label === 'Success' ? 'green' : 'black',
  }),
  control: (provided, state) => ({
    ...provided,
    padding: '3px',
    boxShadow: state.isFocused ? '0 0 5px #e0e0e0' : 'none',
    '&:hover': {
      border: state.isFocused ? `2px solid ${color.primary[300]}` : `1px solid ${color.primary[300]}`,
    },
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    background: 'none',
  }),
  menu: (styles, state) => ({
    ...styles,
    minWidth: '300px',
  }),
  container: (styles, state) => ({
    ...styles,
    width: state.className === 'noWidth' ? '200px' : 'auto',
    zIndex: '999',
    '@media (max-width: 992px)': {
      width: '100%',
      paddingTop: '9px',
    },
  }),
};
