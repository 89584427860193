import { ReactNode, useContext } from 'react';
import { Header, ModalLoading } from '..';
import * as S from './styles';
import React from 'react';
import AuthContext from '../../context/auth';

interface TemplateProps {
  children: ReactNode;
}

function Template({ children }: TemplateProps): JSX.Element {
  const authCtx = useContext(AuthContext);
  return (
    <S.TemplateStyled>
      <Header />
      {children}
      {authCtx.initialLoading && <ModalLoading />}
    </S.TemplateStyled>
  );
}

export default Template;
