import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './routes/index';
import { AuthProvider } from './context/auth';
import { OnboardingProvider } from './context/onboarding';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    return () => {
      localStorage.clear();
    };
  }, []);

  return (
    <Router>
      <AuthProvider>
        <OnboardingProvider>
          <RoutesComponent />
        </OnboardingProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
