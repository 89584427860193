import { FC, ReactNode, createContext, useContext, useReducer } from 'react';
import onboardingStore from './onboardingStore';
import {  userProfile } from '../config/types/user';
import * as services from '../services/onboarding';
import { useNavigate } from 'react-router-dom';
import AuthContext from './auth';

interface IFilter {
  [key: string]: any;
}

interface userProps {
  children?: ReactNode;
  filter?: IFilter;
  setFilter?: any;
  data?: [];
  user?: userProfile;
  loading?: boolean;
  passwordCreated?: boolean;
  validationCode?: any;
  validationEmailCode?: any;
  CustomerCompany?: any;
  getTokenSumsub?: any;
  tokenSumsub?: any;
}

const OnboardingContext = createContext<userProps>({
  filter: {},
  data: [],
  user: {
    email: '',
    phone: '',
    site: '',
    companyName: '',
    taxId: '',
    businessEntity: '',
    adminPassword: '',
    newPassword: '',
  },
  loading: true,
  passwordCreated: false,
  tokenSumsub: '',
});

export const OnboardingProvider: FC<userProps> = ({ children }) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [state, dispatch] = useReducer(onboardingStore, {
    filter: {},
    user: {},
  });

  const setLoading = (loading: boolean) => {
    dispatch({ payload: loading, key: 'loading', action: 'loading' });
  };

  const setPasswordCreated = (passwordCreated: boolean) => {
    dispatch({ payload: passwordCreated, key: 'passwordCreated', action: 'passwordCreated' });
  };

  interface IClearFilter {
    UserType: '';
  }

  const clearFilter = (obj: IClearFilter) => {
    dispatch({
      payload: { ...obj },
      key: 'clear',
      action: 'clear',
    });
  };

  const setFilter = (filter: any) => {
    dispatch({
      payload: { ...state.filter, filter },
      key: 'filter',
      action: 'filter',
    });
  };

  const validationCode = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      services
        .validationCode({ email: authCtx.data.email })
        .then((response) => {
          setLoading(false);
          resolve(response);
        })
        .catch((error: string) => {
          reject(error);
          setLoading(false);
        });
    });
  };

  const validationEmailCode = () => {
    return new Promise((_resolve, reject) => {
      setLoading(true);
      services
        .validationEmailCode({ email: authCtx.data.email, code: authCtx.data.code })
        .then(() => {
          setLoading(false);
          navigate('/accesspassword');
        })
        .catch((error: string) => {
          reject(error);
          setLoading(false);
        });
    });
  };


  const CustomerCompany = () => {
    return new Promise((_resolve, reject) => {
      setLoading(true);
      services
        .CustomerCompany({
          product: 'TransferoCheckout',
          email: authCtx.data.email ?? state.filter.email,
          phoneNumber: state.filter.phone,
          taxId: state.filter.taxId,
          transationalPassword: state.filter.password,
          companyName: state.filter.companyName,
          legalNature: state.filter.businessEntity,
          webSiteUrl: state.filter.site,
          taxCountryCode: 'BRA',
        })
        .then(() => {
          setLoading(false);
          navigate('/sumsub');
        })
        .catch((error) => {
          reject(error);
          setLoading(false);
        });
    });
  };

  const getTokenSumsub = () => {
    services
      .getTokenSumsub()
      .then((response) => {
        dispatch({ payload: response.accessToken, key: 'tokenSumsub', action: 'tokenSumsub' });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <OnboardingContext.Provider
      value={{
        ...state,
        clearFilter,
        setLoading,
        setFilter,
        validationCode,
        validationEmailCode,
        setPasswordCreated,
        CustomerCompany,
        getTokenSumsub,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
export default OnboardingContext;
