import React, { useContext, useEffect } from 'react';
import logo from '../../assets/logo.svg';
import * as S from './styles';
import AuthContext from '../../context/auth';
import { useNavigate } from 'react-router-dom';
interface IFunction {
  [key: string]: any;
}

function Header() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const verifyStepOne = (customer: IFunction) =>
    customer?.email === null || customer?.phoneNumber === null;

  const verifyStepTwo = (customer: IFunction) =>
    customer?.taxId === null || customer?.companyName === null || customer?.legalNature === null;

  const fetchData = async () => {
    try {
      let customer = await authCtx.getCustomer();
      if (customer) {
        if (verifyStepOne(customer)) {
          navigate('/companyregistrationcontact');
        } else if (verifyStepTwo(customer)) {
          navigate('/companyregistration');
        } else if (customer?.transationalPassword === null) {
          navigate('/adminpasswordcreation');
        } else if (customer?.profiles[0].name === 'Basic') {
          navigate('/sumsub');
        } else {
          navigate('/modalresponse');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <S.HeaderContainer>
        <img src={logo} alt='' />
      </S.HeaderContainer>
    </>
  );
}

export default Header;
