import { css } from 'styled-components';
import { color } from '../../styles';

export const sizes = {
  sm: css`
    padding: 12px 16px;
  `,
  md: css`
    padding: 12px 18px;
  `,
  lg: css`
    padding: 14px 20px;
    width: 100%;
  `,
};

export const aligns = {
  left: css`
    justify-content: start;
  `,
  center: css`
    justify-content: center;
  `,
  right: css`
    justify-content: end;
    `,
};

export const variants = {
  primary: css`
    color: white;
    background-color: ${color.primary[500]};

    &:hover {
      background-color: ${color.primary[700]};
    }

    &:disabled {
      background-color: ${color.primary[200]};
    }
  `,
  outlinePrimary: css`
    color: ${color.primary[700]};
    background-color: transparent;
    border: 1px solid ${color.primary[600]};

    &:disabled {
      background-color: ${color.gray[100]};
    }
  `,
  secondary: css`
    color: ${color.primary[700]};
    background-color: ${color.primary[100]};

    &:hover {
      background-color: ${color.primary[200]};
    }

    &:disabled {
      background-color: ${color.primary[50]};
    }
  `,
  outlineSecondary: css`
    color: ${color.primary[200]};
    background-color: transparent;
    border: 1px solid ${color.primary[200]};

    &:hover {
      background-color: ${color.primary[200]};
      color: ${color.primary[700]};
    }

    &:disabled {
      background-color: ${color.gray[100]};
    }
  `,

  error: css`
    color: #fff;
    background-color: ${color.error[600]};

    &:hover {
      background-color: ${color.error[700]};
    }

    &:disabled {
      background-color: ${color.error[200]};
    }
  `,
  outlineError: css`
    color: ${color.error[700]};
    background-color: transparent;
    border: 1px solid ${color.error[700]};

    &:hover {
      background-color: ${color.error[700]};
      color: #fff;
    }

    &:disabled {
      background-color: ${color.error[700]};
    }
  `,
};
