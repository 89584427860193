import styled from 'styled-components';

export const BackButtonStyle = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;

  img {
    padding: 0;
  }
`;
