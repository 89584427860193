import styled from 'styled-components';
import { color } from '../../styles';

export const ModalModalLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
