import styled, { css } from 'styled-components';
import { color } from '../../styles';
interface Props {
  size?: 'sm' | 'md' | 'lg';
}

const sizes = {
  sm: css`
    font-size: 12px;
  `,
  md: css`
    font-size: 14px;
  `,
  lg: css`
    font-size: 18px;
  `,
};

export const ButtonLinkContent = styled.button<Props>`
  text-decoration: none;
  font-weight: 600;
  padding: 4px;
  color: ${color.primary[600]};
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:hover {
    color: ${color.primary[700]};
  }
  ${({ size }) => sizes[size || 'md']}
`;
