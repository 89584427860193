export const color = {
  primary: {
    900: '#2b2b52',
    800: '#353577',
    700: '#3C3C9F',
    600: '#3F3FCA',
    500: '#3F3FF9',
    400: '#5D68FA',
    300: '#7C8EFB',
    200: '#9AAFFC',
    100: '#CAD9FF',
    50: '#E8F0FF',
    25: '#F5F9FF',
  },
  secondary: {
    900: '#15433B',
    800: '#166456',
    700: '#138873',
    600: '#0BB193',
    500: '#00DDB5',
    400: '#28E2B4',
    300: '#51E8B8',
    200: '#79EDC0',
    100: '#BEF6DC',
    50: '#E5FCF0',
    25: '#F2FDF7',
  },
  gray: {
    900: '#111322',
    800: '#30374F',
    700: '#404968',
    600: '#4A5578',
    500: '#5D6B98',
    400: '#7D89B0',
    300: '#B9C0D4',
    200: '#DCDFEA',
    100: '#EFF1F5',
    50: '#F9F9FB',
    25: '#FCFCFD',
  },
  blue: {
    50: '#F8F9FC',
    700: '#363F72',
  },
  blueLight: {
    50: '#F0F9FF',
    700: '#026AA2',
  },
  error: {
    900: '#7A271A',
    800: '#912018',
    700: '#B42318',
    600: '#D92D20',
    500: '#F04438',
    400: '#F97066',
    300: '#FDA29B',
    200: '#FECDCA',
    100: '#FEE4E2',
    50: '#FEF3F2',
    25: '#FFFBFA',
  },
  warning: {
    900: '#7A2E0E',
    800: '#93370D',
    700: '#B54708',
    600: '#DC6803',
    500: '#F79009',
    400: '#FDB022',
    300: '#FEC84B',
    200: '#FEDF89',
    100: '#FEF0C7',
    50: '#FFFAEB',
    25: '#FFFCF5',
  },
  success: {
    900: '#054F31',
    800: '#05603A',
    700: '#027A48',
    600: '#039855',
    500: '#12B76A',
    400: '#32D583',
    300: '#6CE9A6',
    200: '#A6F4C5',
    100: '#D1FADF',
    50: '#ECFDF3',
    25: '#F6FEF9',
  },
  purple: {
    700: '#5925DC',
    50: '#F4F3FF',
  },
  pink: {
    700: '#C11574',
    50: '#FDF2FA',
  },
  orange: {
    700: '#FF4405',
    100: '#EF6820',
  },
};
