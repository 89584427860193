import PinField from 'react-pin-field';
import styled from 'styled-components';
import { color } from '../../styles';

interface IProps {
  error?: boolean;
}

export const ContentPinField = styled.div`
  display: flex;
  height: 55px;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${color.error[500]};
`;

export const PinFieldStyles = styled(PinField)<IProps>`
  border: 1px solid ${(props) => (props.error ? color.error[500] : color.gray[300])};
  width: calc((100% / ${(props) => props.length}) - 10px);
  color: ${(props) => (props.error ? color.error[500] : color.gray[800])};
  box-sizing: border-box;
  font-size: 38px;
  text-align: center;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: ${color.primary[600]};
  }
  a{
    display: none;
  }
`;
