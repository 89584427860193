import api from '../config/services/api';
import { SumsubResponse } from '../config/types/user';

export const validationCode = ({ email }: { email: string }) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await api.post('/validation/email', {
        toEmail: email,
        language: 'pt-br',
      });
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const validationEmailCode = ({ email, code }: { email: string; code: string }) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await api.get(`/validation/email/${email}/code/${code}`);
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const CustomerCompany = (obj: any) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    try {
    const url = '/Customers/Company';
    const response = await api.patch(url, {
      ...obj,
    });
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getTokenSumsub = () => {
  return new Promise<SumsubResponse>(async (resolve, reject) => {
    try {
      const res = await api.get('/sumsub/Token?product=TransferoCheckout');
      resolve({ accessToken: res.data });
    } catch (error) {
      reject(error);
    }
  });
};

