import React, { useContext, useState } from 'react';
import { Button, Card, TextInput, Title } from '../../components';
import PasswordCheck from './componets/PasswordCheck';
import * as S from './styles';
import { color } from '../../styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import AuthContext from '../../context/auth';

const passwordCheckTexts = [
  'Mínimo de 8 caractéres',
  'Ao menos 1 letra maiúscula',
  'Ao menos 1 letra minúscula',
  'Ao menos caracter especial (@#*)',
  'Ao menos 1 número',
];

function AccessPassword() {
  const [isValid, setIsValid] = useState(new Array(passwordCheckTexts.length).fill(false));
  const authCtx = useContext(AuthContext);

  const [messageError, setMessageError] = useState('');

  const onSubmit = () => {
    authCtx.newPasswordCreated().catch((e: any) => {
      setMessageError('Senha inválida');
    });
  };

  function checkPasswordValidity(newPassword: string) {
    const validations = [
      newPassword.length >= 8,
      /[A-Z]/.test(newPassword),
      /[a-z]/.test(newPassword),
      /[@#*!$%^&*()_+|~=`{}\[\]:";'<>?,./]/.test(newPassword),
      /\d/.test(newPassword),
    ];
    setIsValid(validations);
    return validations.every((valid) => valid);
  }

  const SignupSchema = Yup.object().shape({
    newPassword: Yup.string().required('Campo obrigatório.'),
  });

  return (
    <Card backButton textHeader='Etapa 1 de 4' titleCard='Crie uma senha de acesso'>
      <Formik
        initialValues={{ newPassword: '' }}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange }) => (
          <Form>
            <>
              <Title> Nova senha</Title>
              <TextInput
                onChange={(e: any) => {
                  handleChange(e);
                  authCtx.setData({ newPassword: e.target.value });
                  checkPasswordValidity(e.target.value);
                }}
                name='newPassword'
                error={errors.newPassword || messageError}
                password
              />
            </>
            <>
              <Title color={color.gray[600]}>
                Sua senha deve conter as seguintes características:
              </Title>
              {passwordCheckTexts.map((text, index) => (
                <PasswordCheck key={index} isValid={isValid[index]}>
                  {text}
                </PasswordCheck>
              ))}
            </>
            <S.ButtonPasswordCheck>
              <Button type='submit' size='lg' loading={authCtx.loading}>
                Continuar
              </Button>
            </S.ButtonPasswordCheck>
            {/* {onboardingCtx.passwordCreated && (
              <S.PasswordCreated>
                <S.PasswordCreatedContent>
                  <S.PasswordCreatedTitle>
                    <SuccessCircle SuccessCircle={true} />
                    <Title size='lg' fontWeight={600} color={color.success[700]}>
                      Senha criada com sucesso
                    </Title>
                  </S.PasswordCreatedTitle>
                  <Title size='md' fontWeight={400} color={color.success[700]}>
                    Esta conta está configurada e pronta para começar
                  </Title>
                </S.PasswordCreatedContent>
              </S.PasswordCreated>
            )} */}
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default AccessPassword;
