import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Template } from '../components';
import {
  CompanyRegistration,
  AdminPasswordCreation,
  CompanyRegistrationContact,
  Sumsub,
} from '../views';
import ModalResponse from '../components/ModalResponse';

function AppRoutes() {
  return (
    <>
      <Template>
        <Routes>
          <Route path='/adminpasswordcreation' element={<AdminPasswordCreation />} />
          <Route path='/companyregistration' element={<CompanyRegistration />} />
          <Route path='/companyregistrationcontact' element={<CompanyRegistrationContact />} />
          <Route path='/sumsub' element={<Sumsub />} />
          <Route path='/modalresponse' element={<ModalResponse />} />
        </Routes>
      </Template>
    </>
  );
}

export default AppRoutes;
