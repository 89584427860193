import React from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import back from '../../assets/icons/back.svg';

function BackButton() {
  const navigate = useNavigate();

  return (
    <S.BackButtonStyle onClick={() => navigate(-1)}>
      <Icon size='xl' src={back} alt='back' pointer />
    </S.BackButtonStyle>
  );
}

export default BackButton;
