import React, { ReactNode } from 'react';
import * as S from './styled';
import { CSSProperties } from 'styled-components';

interface IButtonLink {
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
  style?: CSSProperties;
}

function ButtonLink({ children, onClick, style, size }: IButtonLink) {
  return (
    <S.ButtonLinkContent onClick={onClick} style={style} size={size}>
      {children}
    </S.ButtonLinkContent>
  );
}

export default ButtonLink;
