import styled from 'styled-components';
import { color } from '../../styles';

export const PasswordCreated = styled.div`
  border: solid ${color.success[200]};
  background-color: ${color.success[25]};
  border-radius: 12px;
  padding-left: 10px;
  p {
    margin: 0;
    padding: 5px;
  }
`;
export const PasswordCreatedContent = styled.div`
padding: 15px;
`
export const PasswordCreatedTitle = styled.div`
  display: flex;
  align-items: center;
`;
export const ButtonPasswordCheck = styled.div`
  margin: 20px 0 20px;
`;
