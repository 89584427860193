import React, { ReactNode } from 'react';
import * as S from './styles';

interface TitletProps {
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  color?: string;
  fontWeight?: number;
}

function Title({ children, size, color, fontWeight }: TitletProps) {
  return (
    <S.Title size={size} color={color} fontWeight={fontWeight}>
      {children}
    </S.Title>
  );
}

export default Title;
