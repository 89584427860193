import React from 'react';
import * as S from './styles';

export interface IIcon {
  src?: string;
  onClick?: any;
  alt?: string;
  pointer?: boolean;
  size?: 'sm' | 'md' | 'xl' | 'xxl';
}

function Icon({ src, onClick, alt, pointer, size }: IIcon) {
  return <S.Icon src={src} onClick={onClick} size={size} alt={alt} pointer={pointer}  />;
}

export default Icon;
