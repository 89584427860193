import styled from 'styled-components';
import { color } from '../../styles';

interface Props {
  id?: string;
}
export const TooltipsContent = styled.div<Props>`
  .react-tooltip{
    max-width: 200px;
    box-shadow: 3px 10px 10px ${color.gray[300]};
    /* padding: 5px; */
    background-color: #ffff;
    color: ${color.gray[900]};
    border-radius: 8px;
    /* font-size: 14px; */
    text-align: center;
    z-index: 999;
  }
`;
