import React from 'react';
import Select from 'react-select';
import * as S from './styles';
import { FormikErrors } from 'formik';
import Title from '../Title';
import { color } from '../../styles';

export interface Option {
  value?: any;
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ children, ...props }) => (
  <label style={{ marginRight: '1em', zIndex: 99999 }}>
    <input type='checkbox' {...props} />
    {children}
  </label>
);

export interface ComboboxProps {
  onChange: (value: any) => void;
  defaultValue?: any;
  options: Option[];
  name: string;
  menuPlacement?: any;
  className?: string;
  value?: string;
  label?: string;
  error?: string | boolean | undefined;
}

const Combobox: React.FC<ComboboxProps> = ({
  defaultValue,
  options,
  name,
  onChange,
  menuPlacement,
  className,
  value,
  label,
  error,
}) => {
  return (
    <>
      <Select
        defaultValue={defaultValue}
        name={name}
        options={options}
        styles={S.CheckboxStyles}
        onChange={(selectedOption) => {
          const selectedValue = selectedOption?.value;
          onChange(selectedValue);
        }}
        menuPlacement={menuPlacement}
        className={className}
        value={value}
        placeholder={label}
      />
      {error && <Title color={color.error[500]}>{error}</Title>}
      <></>
    </>
  );
};

export default Combobox;
