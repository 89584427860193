import React, { useState } from 'react';
import * as S from './styles';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { ITextInput } from './types';
import eyeOpen from '../../assets/icons/eyeOpen.svg';
import eyeClose from '../../assets/icons/eyeClose.svg';
import Title from '../Title';
import { color } from '../../styles';

function TextInput({
  icon,
  tooltipText,
  tooltip,
  alt,
  id,
  onChange,
  disabled,
  error,
  password,
  value,
  name,
  type,
  mask,
}: ITextInput) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <S.InputTextContainer error={String(!!error)}>
        {password ? (
          <S.InputStyled
            onChange={onChange}
            disabled={disabled}
            type={showPassword ? 'text' : 'password'}
            value={value}
            name={name}
          />
        ) : mask ? (
          <S.InputCnpj
            mask={mask}
            onChange={onChange}
            disabled={disabled}
            value={value}
            name={name}
            maskChar={''}
          />
        ) : (
          <S.InputStyled
            onChange={onChange}
            disabled={disabled}
            type={type}
            value={value}
            name={name}
          />
        )}
        {tooltip && <Tooltip id={id} text={tooltipText}></Tooltip>}
        <a data-tooltip-id={id}>{icon && <Icon src={icon} alt={alt} size='md' pointer />}</a>
        {password && (
          <Icon
            src={showPassword ? eyeClose : eyeOpen}
            pointer
            size='xl'
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </S.InputTextContainer>
      {String(!!error) && <Title color={color.error[500]}>{error?.toString()}</Title>}
    </>
  );
}

export default TextInput;
