import React, { useContext } from 'react';
import { Button, Card, TextInput, Title } from '../../components';
import helpCircle from '../../assets/icons/helpCircle.svg';
import * as S from './styles';
import Combobox from '../../components/Combobox';
import arrowRigth from '../../assets/icons/arrowRigth.svg';
import { Form, Formik } from 'formik';
import OnboardingContext from '../../context/onboarding';
import { useNavigate } from 'react-router-dom';
import { SignupSchema, businessEntity } from './utils';

function CompanyRegistration() {
  const onboardingCtx = useContext(OnboardingContext);
  const navigate = useNavigate();

  const inititialValues = {
    taxId: '',
    companyName: '',
    businessEntity: '',
  };

  return (
    <Card backButton textHeader='Etapa 3 de 4' titleCard='Complete os dados para criar sua conta'>
      <Formik
        initialValues={inititialValues}
        validationSchema={SignupSchema}
        onSubmit={() => navigate('/adminpasswordcreation')}
      >
        {({ errors, handleChange }) => (
          <Form>
            <>
              <Title>CNPJ</Title>
              <TextInput
                mask='99.999.999/9999-99'
                icon={helpCircle}
                id='cnpj'
                tooltip
                tooltipText='Insira apenas os números'
                name='taxId'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onboardingCtx.setFilter({ taxId: e.target.value });
                }}
                error={errors.taxId}
              />
            </>
            <>
              <Title>Razão social</Title>
              <TextInput
                icon={helpCircle}
                id='rs'
                tooltip
                tooltipText='Insira exatamente como está no contrato social da empresa'
                name='companyName'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  onboardingCtx.setFilter({ companyName: e.target.value });
                }}
                error={errors.companyName}
              />
            </>
            <>
              <Title>Natureza jurídica</Title>
              <Combobox
                options={businessEntity}
                onChange={(selectedOption: string) => {
                  handleChange({ target: { name: 'businessEntity', value: selectedOption } });
                  onboardingCtx.setFilter({ businessEntity: selectedOption });
                }}
                name='businessEntity'
              />
            </>
            <S.ButtonRegister>
              <Button icon src={arrowRigth} size='lg' variant='primary' type='submit'>
                Continuar
              </Button>
            </S.ButtonRegister>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default CompanyRegistration;
