import styled from 'styled-components';
import 'react-phone-input-2/lib/style.css';
import ReactPhoneInput from 'react-phone-input-2';
import { color } from '../../styles';
import { FormikErrors } from 'formik';
interface Props {
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}
export const StyledInputPhone = styled(ReactPhoneInput)<Props>`
  width: calc(100% - 5px);
  height: 42px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.error === 'true' ? color.error[500] : color.gray[300])};
  padding-left: 5px;

  &:hover {
    border: 1px solid ${color.primary[300]};
  }

  .flag-dropdown {
    background-color: #ffff;
    border: none;
  }

  .form-control {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 8px;

    font-size: 16px;
  }

  .country-list {
    border-radius: 8px;
  }
`;
