import styled, { css } from 'styled-components';

interface Props {
  size?: 'sm' | 'md' | 'lg';
  fontWeight?: number;
  color?: string;
}

const sizes = {
  sm: css`
    font-size: 12px;
  `,
  md: css`
    font-size: 14px;
  `,
  lg: css`
    font-size: 17px;
  `,
};

export const Title = styled.p<Props>`
  text-align: left;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight || 500};
  ${({ size }) => sizes[size || 'md']}
  margin-top: 0;
`;
