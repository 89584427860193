import React from 'react';
import { PulseLoader } from 'react-spinners';
import { color } from '../../styles';
import * as S from './styles';

interface ModalLoadingProps {
  loading?: boolean;
}

function ModalLoading({ loading }: ModalLoadingProps) {
  return (
    <S.ModalModalLoading>
      <PulseLoader size={20} color={color.primary[600]} loading={loading} />
    </S.ModalModalLoading>
  );
}

export default ModalLoading;
