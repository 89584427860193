const setLoading = (action: any, state: any) => {
  return {
    ...state,
    loading: action.payload,
  };
};

const setPasswordCreated = (action: any, state: any) => {
  return {
    ...state,
    loading: action.payload,
  };
};

const clearFilter = (action: any, state: any) => ({
  ...state,
  filter: action.payload,
});

const setTokenSumsub = (action: any, state: any) => ({
  ...state,
  tokenSumsub: action.payload,
});

const setFilter = (action: any, state: any) => {
  return {
    ...state,
    filter: { ...state.filter, ...action.payload.filter },
  };
};

export default (state: any, action: any) => {
  const _state: any = {
    clear: () => clearFilter(action, state),
    filter: () => setFilter(action, state),
    loading: () => setLoading(action, state),
    passwordCreated: () => setPasswordCreated(action, state),
    tokenSumsub: () => setTokenSumsub(action, state),
  };

  return _state[action.action]();
};
