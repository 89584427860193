import React, { ReactNode } from 'react';
import * as S from './styles';
import BackButton from '../BackButton';

interface ICard {
  backButton?: boolean;
  textHeader?: string;
  children: ReactNode;
  titleCard?: string;
  subtitle?: string;
}

function Card({ backButton, textHeader, children, titleCard, subtitle}: ICard) {
  return (
    <S.CardContainer>
      <S.CardContent>
        <S.HeaderCard>
          {backButton && <BackButton />}
          <S.HeaderText>{textHeader}</S.HeaderText>
        </S.HeaderCard>
        <S.TitleCard> {titleCard} </S.TitleCard>
        <S.SubtitleCard> {subtitle} </S.SubtitleCard>
        {children}
      </S.CardContent>
    </S.CardContainer>
  );
}

export default Card;
