import React, { useContext, useState } from 'react';
import { Button, Card, PinField, Title } from '../../components';
import * as S from './styles';
import arrowRigth from '../../assets/icons/arrowRigth.svg';
import OnboardingContext from '../../context/onboarding';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { color } from '../../styles';

function AdminPasswordCreation() {
  const onboardingCtx = useContext(OnboardingContext);
  const [messageError, setMessageError] = useState('');

  const SignupSchema = Yup.object().shape({
    adminPassword: Yup.string()
      .min(4, 'A senha deve ter no mínimo 4 caracteres.')
      .required('Campo obrigatório.'),
  });

  const onSubmit = () => {
    onboardingCtx.CustomerCompany().catch((e: any) => {
      setMessageError(e.message);
    });
  };

  return (
    <Card
      backButton
      textHeader='Etapa 4 de 4'
      titleCard='Crie uma senha administrativa'
      subtitle='Essa senha numérica de 4 dígitos serve para acessar áreas e funções restritas à administração'
    >
      <Formik
        initialValues={{ adminPassword: '' }}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <S.ButtonPassword>
              <PinField
                onChange={(v) => {
                  setFieldValue('adminPassword', v);
                  onboardingCtx.setFilter({ password: v });
                }}
                length={4}
              />
              {errors.adminPassword && touched.adminPassword ? (
                <Title color={color.error[500]}>Campo obrigatório. </Title>
              ) : null}
              {messageError ? <Title color={color.error[500]}>{messageError} </Title> : null}
              <Button
                type='submit'
                icon
                src={arrowRigth}
                size='lg'
                variant='primary'
                loading={onboardingCtx.loading}
              >
                Continuar
              </Button>
            </S.ButtonPassword>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default AdminPasswordCreation;
