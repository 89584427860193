import SumsubWebSdk from '@sumsub/websdk-react';
import React, { useContext, useEffect } from 'react';
import OnboardingContext from '../../context/onboarding';

function Sumsub() {
  const onboardingCtx = useContext(OnboardingContext);

  useEffect(() => {
    onboardingCtx.getTokenSumsub();
  }, []);
  return (
    <>
      {onboardingCtx.tokenSumsub?.accessToken && (
        <SumsubWebSdk
          accessToken={onboardingCtx.tokenSumsub.accessToken}
          expirationHandler={() => onboardingCtx.getTokenSumsub()}
          config={{
            lang: 'pt-br',
            email: onboardingCtx.filter?.email,
            phone: onboardingCtx.filter?.phone,
          }}
        />
      )}
    </>
  );
}

export default Sumsub;
