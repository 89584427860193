import styled, { css } from 'styled-components';
import { color } from '../../styles';

interface Props {
  size?: 'sm' | 'md' | 'xl' | 'xxl';
  pointer?: boolean;
}

const sizes = {
  sm: css`
    height: 10px;
    width: 10px;
    padding: 5px;
  `,
  md: css`
    height: 15px;
    width: 15px;
    padding: 8px;
  `,
  xl: css`
    height: 20px;
    width: 20px;
    padding: 10px;
  `,
  xxl: css`
    height: 75px;
    width: 75px;
    padding: 10px;
  `,
};

export const Icon = styled.img<Props>`
  ${({ size }) => sizes[size || 'md']}
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  svg {
    fill: ${color.primary[800]};
  }
`;
